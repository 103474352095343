<template>
  <div id="folder-edit">
    <Headline />
    <FolderEdit />
  </div>
</template>

<script>
import Headline from '../../components/app/Headline'
import FolderEdit from '../../components/folder/FolderEdit'

export default {
  components: {
    Headline,
    FolderEdit
  }
}
</script>
