<template>
  <FolderSettings mode="edit" :route-on-action="{ name: 'folders_index' }" :folder-id="this.$route.params.folder_id"></FolderSettings>
</template>

<script>
import FolderSettings from '../../components/folder/FolderSettings'

export default {
  components: {
    FolderSettings: FolderSettings
  }
}
</script>
